<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true"  @close="closeDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div class="detail_cont">
                    <h3 class=" dialog_normal_question-head margin-bottom20">Contact the selected</h3>
                    <h4 class="Exportofflinetab margin-bottom20"><span :class="ExportofflinetabActive==0?'ExportofflinetabActiveSPan':''" @click="showCompanyCilick(0)">Downloadable</span><span :class="ExportofflinetabActive==1?'ExportofflinetabActiveSPan':''" @click="showCompanyCilick(1)">Downloaded</span> </h4>

                    <div class="Exportoffline ">
            
                      <!-- <p style="margin-bottom: 10px; " class="flex_center_bettwen"><span>Found over {{ total}}: </span>   <el-button 
                :class="remouveFavorite.length == 0 ? ' btn_export btn_exporthui' : 'btn_exportred btn_export'"
                :disabled="remouveFavorite.length == 0" @click="CollectDelate">Remove</el-button></p> -->
                   
                        <div class="table_box company_list margin-bottom20" 
                            ref="tableBox">
                            
                            <el-table ref="treeTable"
                                :key="refresh" :data="parentTable" empty-text="No Data"
                                :border="true" cell-class-name="company line_1" row-key="aaa_id"
                               >
                          
                                <el-table-column label="Company Name" min-width="365" prop="company_name_en"
                                    align="left" class-name="company  " show-overflow-tooltip
                                    :resizable="true">
                                    <template slot-scope="scope">
                                        <div class="line_1" style="min-width: 345px">
                                            <!-- <div class="btn_expand" :class="{ expand_icon: scope.row.isExpand }"
                                                @click="onClickExpansion(scope.row)"></div> -->

                                            <!-- <img @click="gotocountry(scope.row)" :src="scope.row.flag" /> -->
                                            <div @click="setid(scope.row)" class=" line_1">
                                                {{
                                                    (scope.row.companyName == '' || scope.row.companyName == undefined || scope.row.company_name_en == null
                                                )?scope.row.companyName:scope.row.companyName}}
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                          



                            </el-table>

                        </div>
                        <button @click="ConfirmClikc()" :disabled="NowUpdateList.length==0" class="riskButton tijiao ExportofflinetabActive">Confirm</button>

                    </div>
                 

            </div>

        </el-dialog>


    </div>
</template>

<script>
import { getUserConsumptionBatch, } from "@/api/userApi";
import { mixins1 } from "@/mixins/index";
export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
        ComapnyName: {
            type: String
        },
        Saved: {
            type: Number
        }
    },
    mixins: [mixins1],
    data() {
        return {
            checkAll: false,
            ExportofflinetabActive:0,
            isIndeterminate: true,
            UpdateEndDialog: false,
            uPdateDialog: false,
            refresh: 0,
            parentTable: [],
            pageNo:0,
            lastUpdateList:[],
            NowUpdateList:[],
            total:0,
            remouveFavorite:[],
            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    mounted() {
  
        
    },
    watch:{
        newDialog :{
            handler(newVal, oldVal) {
                if (newVal) {
                    this.getShouCompanyList()
               
                }

                // 在这里处理Sort属性的变化
            },
            deep: true, // 深度监听，可以监听对象内部属性的变化
            immediate: true, // 立即执行一次handler函数，即使columns没有变化
        },
    },

    methods: {
     getShouCompanyList(){
        let Updatelis = []

this.$store.state.app.changeList.map((item) => {
    Updatelis.push({
        countryCode: item.company_country,
        companyName: item.company_name_en,
        companyAaaid: item.aaa_id

    });
});
getUserConsumptionBatch(Updatelis).then(res => {
    if (res.code == 200) {
    let that = this

        this.lastUpdateList=[]
        this.NowUpdateList=[]
        res.data.map(item => {
            if (item.consumptionStatus == 1) {
                that.lastUpdateList.push(item)
            }
            if (item.consumptionStatus == 2) {
                that.NowUpdateList.push(item)

            }
        })
       if(this.ExportofflinetabActive==0){
        this.parentTable=this.NowUpdateList
       }else{
        this.parentTable=this.lastUpdateList
       }
       
        

    }else{
        this.$message({
                        message: res.msg,
                        type: 'error',
                    })
    }

})

     },
        // 删除收藏
        showCompanyCilick(index){
         
     this.ExportofflinetabActive=index
     this.getShouCompanyList()
        },
        handleSelectionChange(val) {
            this.remouveFavorite=val
        },

        UpdatecloseDialog() {
            this.UpdateEndDialog = false
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeSaveDialog', false);
        },
        ConfirmClikc(){
            this.$emit('closeSaveDialog', false);
            this.$parent.uPdateDialog=true
        },
        onSubmitkoudian() {
            this.uPdateDialog = false;


            //  this.$emit('closeCloseDialog1', true);


        },
        // 全选



    },
};
</script>
<style scoped>
.detail_cont {


}



.Exportoffline {
    border-radius: 6px;
    font-size: 1.4285714285714286rem;
    /* margin-bottom: 20px; */
    margin-top: 10px;
    text-align: center;
}

.questionExportButton {
    background: #EEEEEE !important;
    /* border-radius: 10px; */
    font-size: 1.14285714rem;
    color: #999999;
    margin-left: 30px;
    font-size: 1.42857143rem;
    color: #1A2332;
    line-height: 26px;
}

/* /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ED8850 !important;
    border-color: #ED8850 !important;
} */

/deep/ .table_box .el-table th>.cell {
    padding-right: 15px !important;
  
}
.company_list{
    height: calc(100vh - 26.428571rem);
}


.ExportCheckbook {
    height: 1.7142857rem;
    margin-top: 1.42857143rem;
}

.detail_cont_texttwo {
    font-size: 1.28571429rem;
    color: #666666;
    margin-top: 2.14285714rem;
    word-break: keep-all;
    text-align: justify;
}

/deep/ .el-checkbox-group {
    font-size: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/deep/ .el-checkbox-group .el-checkbox {
    width: 45%;
    margin-right: 0px !important;
    margin-bottom: 1.42857143rem;
    align-items: center;
}
.Exportofflinetab{
    text-align: center;
    margin-top: 0px;
}
.Exportofflinetab span{
 
    min-height: 22%;
    line-height: 22px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: 1.714285714rem;
}
.ExportofflinetabActive{
    background: #8E86DD !important;
    color: #fff !important;
}
.ExportofflinetabActiveSPan{
    color: #685ED1 !important;
    border-bottom: 2px solid #685ED1;
    font-size: 1.714285714rem;
    margin:0 30px;
}
.tijiao{
    width: 10rem;
    text-align: center;
    min-height: 38px;
    line-height: 38px;
    border-radius: 10px;
    font-size: 1.28571429rem;
}
button:disabled {
    background-color: #cccccc !important;
}
</style>
