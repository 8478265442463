<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true" @close="closeDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class="dialog_normal_question-head"> Search Leads</h3>
                    <div class="detail_cont_text" style="padding: 0px;">
                        Search Leads is a powerful tool designed to help business users connect with potential customers
                        or vendors. Driven by cutting-edge machine learning technology, our platform analyzes extensive
                        datasets to deliver tailored and precise recommendations.
                    </div>
                    <div class="detail_cont_text" style="padding: 0px;">

                        The system takes into account user goals, market trends, risk tolerance, and historical data to
                        pinpoint products or services that best match their specific needs and preferences.

                    </div>
                    <div class="detail_cont_text" style="padding: 0px;">
                        Continuously learning and evolving, "Search Leads" ensures its recommendations remain relevant
                        and accurate. By offering actionable insights, it empowers businesses to save time and resources
                        while identifying the most promising customers or vendors.
                    </div>
                    <div class="detail_cont_text" style="padding: 0px;">
                        <!-- v-if="questionsNum==3" -->
                        In order for the system to recommend a list of companies, we need you to answer few questions...
                    </div>

                </div>
            </div>
            <div class="flex_center">
                <el-button class="btn_export btn_exportYell riskButton" @click="onSubmitkoudian()"><span
                   >Let's Begin</span></el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    props: {
        suoClose: {
            type: Boolean,
        },

    },
    data() {
        return {
            //page: this.currentPage
            questionsNum: 0,
            token: localStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        //关闭弹窗
        closeDialog() {
            this.$emit('closeCloseDialog', false);
        },
        onSubmitkoudian() {
            if(this.token){
                this.$emit('closeCloseDialog', false);

            }else{
                this.$parent.$parent.loginDialog=true
            }
        },
        onSubmitquxiao() {
        },
    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 85vh;
    overflow: auto;
    min-height: 216px;
    margin-bottom: 8.35714276rem;
}



.btn_exportYell {
    background: #8E86DD !important;
    border-radius: 10px;
}
.dialog_normal_question-head{
    font-size: 2.142857142857143rem;
    color: #1A2332;
    text-align: center;
}
</style>
