<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true"  @close="closeDialog"
            custom-class="dialog_normal_Saved" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class=" dialog_normal_question-head margin-bottom20">Saved</h3>

                    <div class="Exportoffline">
                      <p style="margin-bottom: 10px; " class="flex_center_bettwen"><span>Found over {{ total}}: </span>   <el-button 
                :class="remouveFavorite.length == 0 ? ' btn_export btn_exporthui' : 'btn_exportred btn_export'"
                :disabled="remouveFavorite.length == 0" @click="CollectDelate">Remove</el-button></p>
                   
                        <div class="table_box company_list table_container" id="tableBox" @scroll="handleScroll($event)"
                            ref="tableBox">
                            
                            <el-table @selection-change="handleSelectionChange" ref="treeTable" id="tableNode"
                                :key="refresh" :data="parentTable" :height="'calc(100vh - 272px)'" empty-text="No Data"
                                :border="true" cell-class-name="company line_1" row-key="aaa_id"
                               >
                                <el-table-column type="selection" width="40 " align="center">
                                </el-table-column>

                                <el-table-column label="Company Name" min-width="365" prop="company_name_en"
                                    align="left" class-name="company company_link " show-overflow-tooltip
                                    :resizable="true">
                                    <template slot-scope="scope">
                                        <div class="line_1" style="min-width: 345px">
                                            <!-- <div class="btn_expand" :class="{ expand_icon: scope.row.isExpand }"
                                                @click="onClickExpansion(scope.row)"></div> -->

                                            <img @click="gotocountry(scope.row)" :src="scope.row.flag" />
                                            <div @click="setid(scope.row)" class="tbl_company line_1">
                                                {{
                                                    (scope.row.company_name_en == '' || scope.row.company_name_en == undefined || scope.row.company_name_en == null
                                                )?scope.row.company_name:scope.row.company_name_en}}
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Location"  prop="" align="left" show-overflow-tooltip
                                    :resizable="false">
                                    <template slot-scope="scope">
                                        <div class="line_1">
                                            {{ scope.row.city_en }}
                                            <span class="tbl_dot" v-show="scope.row.province_en &&
                                                scope.row.province_en != ' ' &&
                                                scope.row.province_en != '' &&
                                                scope.row.city_en != '' &&
                                                scope.row.city_en != ' ' &&
                                                scope.row.city_en
                                                ">,</span>
                                            {{ scope.row.province_en }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Industry" prop="isic_en" align="left" show-overflow-tooltip
                                    :resizable="false" />
                                <el-table-column label="Inc.Yr." prop="incorporation_year" align="left"
                                    show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Status" prop="status_en" align="left" show-overflow-tooltip
                                    :resizable="false" />
                                <el-table-column label="Type" prop="company_type_en" align="left" show-overflow-tooltip
                                    :resizable="false">
                                </el-table-column>

                                <el-table-column label="Size" prop="scale_en" show-overflow-tooltip :resizable="false"
                                    align="left" />



                            </el-table>

                        </div>
                    </div>


                </div>
            </div>

        </el-dialog>


    </div>
</template>

<script>
import { searchCompany, selectGroup, delCollection } from "@/api/searchApi.js";
import { addItems, showItems, removeItems } from "@/api/userApi";
import { mixins1 } from "@/mixins/index";
export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
        ComapnyName: {
            type: String
        },
        Saved: {
            type: Number
        }
    },
    mixins: [mixins1],
    data() {
        return {
            checkAll: false,
            checkedCities: ['Company contact information', 'Industry situation of the company'],
            cities: ['Company supply chain', 'Company contact information', 'Company contact information', 'Company supply chain', 'Industry situation of the company', 'Industry situation of the company'],
            isIndeterminate: true,
            UpdateEndDialog: false,
            Updatechecked: true,
            reportschecked: true,
            uPdateDialog: false,
            refresh: 0,
            parentTable: [],
            pageNo:0,
            total:0,
            remouveFavorite:[],
            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    mounted() {
    },
    watch:{
        newDialog :{
            handler(newVal, oldVal) {
                if (newVal) {
                    this.parentTable=[]
                    this.getCollection()
                }

                // 在这里处理Sort属性的变化
            },
            deep: true, // 深度监听，可以监听对象内部属性的变化
            immediate: true, // 立即执行一次handler函数，即使columns没有变化
        },
    },

    methods: {
        // 获取收藏
        getCollection() {

            showItems({
                collectionId: this.Saved,
                page: this.pageNo,
                size: 100,
            }).then((res) => {

                if (res.code == 200) {
                    this.Remove = true
                    // this.$store.dispatch("app/setchangeList", []);
                    // this.$store.dispatch("app/setTools", {
                    //     name: "collect",
                    //     key: "isActive",
                    //     value: false,
                    // });
                    // this.$store.dispatch("app/setTools", {
                    //     name: "sort",
                    //     key: "isActive",
                    //     value: false,
                    // });
                    // this.$store.dispatch("app/setTools", {
                    //     name: "filter",
                    //     key: "isActive",
                    //     value: false,
                    // });
                    this.loading = false;
                    let parentTable = [];
                    let list = (parentTable = res.data.rows);
                    this.total=res.data.total
                    //查找本地图片
                    list = list.map((item, index) => {
                        let countryImg = this.getCountryImgUrl(item.company_country);
                        if (countryImg) {
                            let flag = require("../../../../../static" + countryImg);
                            parentTable[index].flag = flag;
                            this.refresh++;
                        }
                    });
                    this.parentTable.push(...parentTable)
                } else {
                    this.loading = false;
                    // this.addPopIndex2=true
                    this.parentTable = [];
                    if (res.ret == "1002") {
                        this.ret = true;
                    } else {
                        this.ret = false;
                    }
                    this.total=res.data.total
                    // this.page.total = res.data.total;
                }
            });
        },
        // 收藏
        CollectCheckBook() {
            this.checkList = this.$store.state.app.collectList
            let collect = [];
            this.$store.state.app.changeList.map((item) => {
                collect.push(item.aaa_id);
            });

            // this.$store.state.app.changeLis;
            this.checkList.forEach((item) => {
                addItems({
                    collectionId: item,
                    items: collect,
                }).then((Res) => {
                    if (Res.code == 200) {
                        this.$message({
                            message: Res.msg,
                            center: true,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: Res.msg,
                            center: true,
                            type: "error",
                        });
                    }
                });
            });
        },
        // 删除收藏
        CollectDelate() {

            let ids = []
            this.remouveFavorite.map((item) => {
                ids.push(item.collectId);
            });
            removeItems({ ids }).then(res => {
                if (res.code == 200) {
                    this.pageNo = 1
                    this.parentTable = []
                    this.getCollection()
                    this.$parent.findPartnerDetailList()
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: "success",
                    });
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: "error",
                    });
                }
            })
        },
        handleSelectionChange(val) {
            this.remouveFavorite=val
        },

        UpdatecloseDialog() {
            this.UpdateEndDialog = false
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeSaveDialog', false);
        },
        onSubmitkoudian() {
            this.uPdateDialog = false;


            //  this.$emit('closeCloseDialog1', true);


        },
        // 全选



    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 86vh;
    overflow: auto;

}


.Exportoffline {
    background: #F5F5F5;
    border-radius: 6px;
    padding: 1.42857143rem;
    /* margin-bottom: 1.42857143; */
    margin-top: 10px;
    font-size: 1.42857143rem;
    color: #1A2332;
    line-height: 26px;
}

.questionExportButton {
    background: #EEEEEE !important;
    /* border-radius: 10px; */
    font-size: 1.14285714rem;
    color: #999999;
    margin-left:2.14285714rem;
}

/* /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ED8850 !important;
    border-color: #ED8850 !important;
} */

/deep/ .table_box .el-table th>.cell {
    padding-right: 15px !important;
  
}


.ExportCheckbook {
    height: 1.7142857rem;
    margin-top: 1.42857143rem;
}

.detail_cont_texttwo {
    font-size: 1.28571429rem;
    color: #666666;
    margin-top: 2.14285714rem;
    word-break: keep-all;
    text-align: justify;
}

/deep/ .el-checkbox-group {
    font-size: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/deep/ .el-checkbox-group .el-checkbox {
    width: 45%;
    margin-right: 0px !important;
    margin-bottom: 1.42857143rem;
    align-items: center;
}
/deep/ .el-radio__label{
    font-size: 1.4285714285714286rem;
}

</style>
