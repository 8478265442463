<template>
    <div style="height: 100%">
        <!-- <div class="leads-head">
            <h3 class="leads-head-title">Search Leads</h3>
            <h4 class="leads-head-titleTwo">
                In order for the system to recommend a list of companies, we need you to
                answer few questions...
            </h4>
        </div> -->
        <el-dialog :visible.sync="questionWentiDialog" center :close-on-click-modal="true" @close="closeDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <h3 class="dialog_normal_question-head">Your search criteria for customer acquisition</h3>

            <div class="leads-content">
                <div class="leads-flex">
                    <!-- <div class="leads-content-left">
                    <img src="../../../../assets/images/searchLeads-logo.png" alt="" />
                    <div class="leads-content-left-Savelist" v-if="SavedAnswersList.length > 0">
                        <h2 class="SavedAnswers-title">My Saved Answers</h2>
                        <ul>
                            <li v-for="(item, index) in SavedAnswersList" @click="SavedAnswerIndex(item, index)"
                                :key="index" :class="index == SavedAnswerIndexActive
                                    ? 'SavedAnswers-list SavedAnswers-listActive '
                                    : 'SavedAnswers-list'
                                    ">
                                {{ item.title
                                }}<svg v-if="index == SavedAnswerIndexActive" t="1732858667612" class="icon right-icon"
                                    viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5869"
                                    width="1.1428571428571428rem" height="1.1428571428571428rem">
                                    <path
                                        d="M170.67136 939.009605V85.411051C170.67136 38.225925 210.262496 0 259.580766 0c22.95262 0 43.686771 8.447247 59.386704 21.928711l498.899515 422.191688a82.936605 82.936605 0 0 1 0 135.92388l-505.128293 426.969929a91.298526 91.298526 0 0 1-124.14893-17.065145 80.718136 80.718136 0 0 1-17.918402-50.939458z"
                                        fill="#8E86DD" p-id="5870"></path>
                                </svg>
                            </li>
                        </ul>
                    </div>
                    <div class="leads-content-left-text" v-else>
                        "Find Partners" is a tool for business users to connect with
                        potential customers or vendors. Powered by advanced machine learning
                        algorithms, our system analyzes vast amounts of data to provide
                        personalized and accurate recommendations. Our system leverages
                        user’s objectives, market preferences, risk appetites and historical
                        data to identify products or services that align with their needs
                        and preferences. We continually learns and adapts, ensuring that its
                        recommendations stay up-to-date and accurate. It can providing
                        businesses with valuable insights and saving them time and effort in
                        identifying the best potential customers or vendors.
                    </div>
                    <div class="leads-content-left-jiantou">
                        <img src="../../../../assets/images/leadsjaintou.png" alt="" />
                    </div>
                </div> -->
                    <div class="leads-content-right">
                        <div class="leads-content-right-content">
                            <!-- <div class="progress">
                            <div class="progressnum">{{ currentIndex + 1 }} of 3</div>
                            <el-progress :stroke-width="10" :percentage="percentage" color="#8E86DD"
                                define-back-color="#EEEEEE"></el-progress>
                        </div> -->
                            <!-- <el-carousel ref="carousel" :interval="0" :trigger="trigger" indicator-position="none"
                            arrow="never" direction="horizontal" :loop="false" @change="handleCarouselChange"> -->
                            <!-- <el-carousel-item v-for="(item, index) in carouselItems" :key="index">
                                <div style="text-align: left"> -->
                            <div>
                                <div v-for="(item, index) in QuestionPageList" :key="index">
                                    <div class="question-step-con" v-if="item.leadsQuestions?.pageNo == 1">
                                        <p class="question-step-question">
                                            <span class="bitian" v-if="item.leadsQuestions?.required == 1">*</span>{{
                                                item.leadsQuestions.questionContent }}
                                        </p>
                                        <div v-if="item.leadsQuestions.type == 1">

                                            <el-cascader v-model="item.showCode" ref="cascader" filterable
                                                @blur="cascaderflase" :popper-append-to-body="false"
                                                :props="cascaderProps" :options="item.leadsOptions"
                                                :placeholder="item.leadsQuestions.defaultTips"
                                                @change="handleChange">
                                            </el-cascader>
                                        </div>
                                        <div v-if="item.leadsQuestions.type == 2">
                                            <el-select filterable style="width: 100%" ref="upSelect"
                                                :popper-append-to-body="false" v-click-outside="clickOutSide"
                                                value-key="optionsCode" @change="leadsQuestionschange"
                                                v-model="item.selectedCodes"
                                                :placeholder="item.leadsQuestions.defaultTips">
                                                <el-option v-for="(ite) in item.leadsOptions" :key="ite.optionsCode"
                                                    :label="ite.optionsContent" :value="ite">
                                                </el-option>
                                            </el-select>
                                        </div>

                                    </div>
                                    <div class="question-step-con" v-if="item.leadsQuestions?.pageNo == 2">
                                        <p class="question-step-question">
                                            <span class="bitian" v-if="item.leadsQuestions?.required == 1">*</span>{{
                                                item.leadsQuestions.questionContent }}

                                        </p>
                                        <div v-if="item.leadsQuestions.type == 2">
                                            <el-select style="width: 100%" clearable value-key="optionsCode"
                                                ref="upSelectwo" :popper-append-to-body="false"
                                                v-model="item.selectedCodes"
                                                :placeholder="item.leadsQuestions.defaultTips">
                                                <el-option v-for="(ite) in item.leadsOptions" :key="ite.optionsCode"
                                                    :label="ite.optionsContent" :value="ite">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div v-if="item.leadsQuestions.type == 3">
                                            <el-select style="width: 100%" clearable value-key="optionsCode"
                                                ref="upSelectduoxuan" :multiple="true" :popper-append-to-body="false"
                                                v-model="item.selectedCodes"
                                                :placeholder="item.leadsQuestions.defaultTips">
                                                <el-option v-for="(ite) in item.leadsOptions" :key="ite.optionsCode"
                                                    :label="ite.optionsContent" :value="ite">
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div v-if="item.leadsQuestions.type == 8 &&  item.leadsQuestions.required == 1" style="position: relative;">
                                            <el-select filterable style="width: 100%;" :loading="loading"
                                               value-key="optionsCode"
                                                ref="upSelectduoxuanSearch"
                                                v-model="item.selectedCodes" remote
                                                :remote-method="debouncedRemoteMethod"
                                                :popper-append-to-body="false"
                                                :placeholder="item.leadsQuestions.defaultTips">
                                                <!--  -->
                                            
                                                <el-option v-for="(ite) in item.leadsOptions" :key="ite.optionsCode"
                                                    :label="ite.optionsContent" :value="ite">
                                                    <span style="display: block;max-width: 600px;"
                                                        v-html="highlightKeyword(ite.optionsContent)"></span>
                                                </el-option>
                                            </el-select>
                                            <i  class="el-saech-eye el-input__icon el-icon-search"></i>
                                            <!-- {{
                                                        item.selectedOptions
                                                    }} -->
                                        </div>
                                        <div v-if="item.leadsQuestions.type == 9" style="position: relative;">
                                            <el-select filterable :loading="loading" style="width: 100%;"
                                                :popper-append-to-body="false" ref="upSelectduoxuanSearch"
                                                value-key="aaa_id" v-model="item.selectedCodes" remote :multiple="true"
                                                :remote-method="item.leadsQuestions.code == 6 ? debouncedRemoteMethodCompany : debouncedRemoteMethodCompany3"
                                                :placeholder="item.leadsQuestions.defaultTips">
                                                <!-- <template slot="prefix">
                                                    <i class="el-input__icon el-icon-search"></i>
                                                </template> -->
                                                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
                                                <el-option v-for="(ite) in item.leadsOptions" :key="ite.aaa_id"
                                                    :label="ite.optionsContent" :value="ite">
                                                    <div class="flex_center_start_box Search_Company"> <img
                                                            class="Country_img" :src="ite.flag" /><span
                                                            class="Country_name">{{ ite.optionsContent }}</span>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                            <i  class="el-saech-eye el-input__icon el-icon-search"></i>
                                        </div>

                                    </div>


                                </div>


                                <el-collapse v-if="QuestionPageList.length>6" v-model="activeNames" @change="handleactiveNamesChange">
                                    <el-collapse-item title="Optional Questions" name="1" >
                                        <div v-for="(item, index) in QuestionPageList" :key="index">
                                            <div class="question-step-con" v-if="item.leadsQuestions?.pageNo == 3">
                                                <p class="question-step-question">
                                                    <span class="bitian"
                                                        v-if="item.leadsQuestions?.required == 1">*</span>{{
                                                            item.leadsQuestions.questionContent }}
                                                </p>
                                                <el-input v-if="item.leadsQuestions.type == 7" id="ceb3e3"
                                                    v-model="item.selectedCodes"
                                                    :placeholder="item.leadsQuestions.defaultTips">
                                                </el-input>
                                                <div v-if="item.leadsQuestions.id == 11">
                                                    <el-cascader v-model="item.showCode" ref="cascader" filterable
                                                        :popper-append-to-body="false" :props="cascaderProps"
                                                        :options="item.leadsOptions"
                                                        :placeholder="item.leadsQuestions.defaultTips"
                                                        @change="handleChangetwo">
                                                    </el-cascader>
                                                </div>
                                                <div
                                                    v-if="item.leadsQuestions.type == 2 && item.leadsQuestions.id != 11">
                                                    <el-select style="width: 100%" ref="upSelectwo"
                                                        value-key="optionsCode" filterable v-model="item.selectedCodes"
                                                        :placeholder="item.leadsQuestions.defaultTips">
                                                        <el-option v-for="(ite) in item.leadsOptions"
                                                            :key="ite.optionsCode" :label="ite.optionsContent"
                                                            :value="ite">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div v-if="item.leadsQuestions.type == 3">
                                                    <el-select style="width: 100%" clearable value-key="optionsCode"
                                                        ref="upSelectduoxuan" :multiple="true"
                                                        :popper-append-to-body="false" v-model="item.selectedCodes"
                                                        :placeholder="item.leadsQuestions.defaultTips">
                                                        <el-option v-for="(ite) in item.leadsOptions"
                                                            :key="ite.optionsCode" :label="ite.optionsContent"
                                                            :value="ite">
                                                        </el-option>
                                                    </el-select>
                                                </div>

                                                <div v-if="item.leadsQuestions.type == 9">
                                                    <el-select filterable clearable :loading="loading"
                                                        style="width: 100%;" :popper-append-to-body="false"
                                                        ref="upSelectduoxuanSearch" value-key="aaa_id"
                                                        v-model="item.selectedCodes" remote :multiple="true"

                                                        :remote-method="debouncedRemoteMethodCompany3"
                                                        :placeholder="item.leadsQuestions.defaultTips">
                                                        <template slot="prefix">
                                                            <i class="el-input__icon el-icon-search"></i>
                                                        </template>
                                                        <el-option v-for="(ite) in item.leadsOptions" :key="ite.aaa_id"
                                                            :label="ite.company_name_en" :value="ite">
                                                            <div class="flex_center_start_box Search_Company"> <img
                                                                    class="Country_img" :src="ite.flag" /><span
                                                                    class="Country_name">{{ ite.company_name_en
                                                                    }}</span></div>
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                                <div v-if="item.leadsQuestions.type == 5">
                                                <div  style="display: flex;margin-bottom: 10px;" >
                                                    <el-radio v-model="item.selectedOptions" label="Yes"
                                                        >Yes</el-radio>
                                                    <el-radio v-model="item.selectedOptions" label="No"
                                                        >No</el-radio>
                                                </div>
                                              <div>
                                                <el-input  id="ceb3e3"
                                                    v-model="item.selectedCodes" v-if="item.selectedOptions=='Yes'"
                                                     :placeholder="item.leadsQuestions.defaultTips">
                                                </el-input>
                                              </div>
                                                </div>

                                            </div>
                                            </div>
                                    </el-collapse-item>

                                </el-collapse>

                            </div>



                            <!-- <div v-if="index < 3" class="flex_center">
                                        <button v-if="index > 0" style="margin-right: 20px;"
                                            class=" btn_exportyellow flex_center" @click="goToNextSlide1(index)"
                                            :disabled="false">
                                            <svg style="margin-right: 5px;" t="1732870972575" class="icon"
                                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                p-id="6417" width="1.5714285714285714rem"
                                                height="1.5714285714285714rem">
                                                <path
                                                    d="M938.666667 533.333333a21.333333 21.333333 0 0 1-21.333334 21.333334H158.166667l134.253333 134.246666a21.333333 21.333333 0 1 1-30.173333 30.173334l-170.666667-170.666667a21.333333 21.333333 0 0 1 0-30.173333l170.666667-170.666667a21.333333 21.333333 0 0 1 30.173333 30.173333L158.166667 512H917.333333a21.333333 21.333333 0 0 1 21.333334 21.333333z"
                                                    fill="#ffffff" p-id="6418"></path>
                                            </svg>
                                            <span>Previous Step</span>

                                        </button>
                                        <button v-if="index < 2" class=" btn_exportyellow flex_center"
                                            @click="goToNextSlide(index)" :disabled="false" style="margin-right: 20px;">
                                            <span v-show="index == 0">Next Step</span>
                                            <span v-if="index == 1">Optional Condition</span>
                                            <svg style="margin-left: 5px" t="1732871110613" class="icon"
                                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                p-id="6900" width="1.5714285714285714rem"
                                                height="1.5714285714285714rem">
                                                <path
                                                    d="M85.333333 533.333333a21.333333 21.333333 0 0 0 21.333334 21.333334h759.166666l-134.253333 134.246666a21.333333 21.333333 0 0 0 30.173333 30.173334l170.666667-170.666667a21.333333 21.333333 0 0 0 0-30.173333l-170.666667-170.666667a21.333333 21.333333 0 0 0-30.173333 30.173333L865.833333 512H106.666667a21.333333 21.333333 0 0 0-21.333334 21.333333z"
                                                    fill="#ffffff" p-id="6901"></path>
                                            </svg>
                                            <svg style="margin-left: 5px" t="1732779157192" class="icon"
                                                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                p-id="6683" width="22" height="22">
                                                <path
                                                    d="M85.333333 533.333333a21.333333 21.333333 0 0 0 21.333334 21.333334h759.166666l-134.253333 134.246666a21.333333 21.333333 0 0 0 30.173333 30.173334l170.666667-170.666667a21.333333 21.333333 0 0 0 0-30.173333l-170.666667-170.666667a21.333333 21.333333 0 0 0-30.173333 30.173333L865.833333 512H106.666667a21.333333 21.333333 0 0 0-21.333334 21.333333z"
                                                    fill="#999999" p-id="6684"></path>
                                            </svg>
                                        </button>
                                        <button  class=" btn_exportyellow flex_center" @click="Finish()"
                                            :disabled="false">
                                            <span>Show Result</span>
                                        </button>

                                    </div> -->

                            <!-- </div>
                            </el-carousel-item> -->
                            <!-- </el-carousel> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class=" question_bottom">

                <button class=" btn_exportyellow riskButton" :disabled="QuestionPageList.slice(0, 6).filter(item => (item.selectedCodes == null||item.selectedCodes.length==0)).length > 0"  @click="Savemyanswers">
                    Save my answer
                </button>
                <div>
                    <button class=" btn_exportyellow riskButton"  style="background-color: #E7E5FF;color:#8E86DD;margin-right: 5px;"
                        @click="questionWentiDialog=false">
                        Cancel
                    </button> 
                    <button  :disabled="QuestionPageList.slice(0, 6).filter(item => (item.selectedCodes == null||item.selectedCodes.length==0)).length > 0" class=" btn_exportyellow riskButton" @click="ShowResult(false)">
                        Show result
                    </button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="questionDialog" center :close-on-click-modal="true" @close="closeDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3>Your search criteria for customer acquisition</h3>
                    <div class="question-detail_cont">
                        <ul>
                            <li class="question-detail_cont-list" v-for="(item, index) in quesitonList" :key="index">
                                <p>{{ item.label }}</p>
                                <h3>{{ item.value }}</h3>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div class=" question_bottom">

                <button class=" btn_exportyellow riskButton" @click="Savemyanswers">
                    Save my answers
                </button>
                <div>
                    <button class=" btn_exportyellow riskButton" style="background-color: #E7E5FF;color:#8E86DD;margin-right: 5px;"
                        @click="closeDialog">
                        Cancel
                    </button> <button class=" btn_exportyellow riskButton" @click="ShowResult(false)">
                        Show Result
                    </button>
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="SaveDialog" center :close-on-click-modal="true" @close="closeSaveDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3  v-if="$parent.isNewQuestion" class="dialog_normal_question-head">You have not saved the existing issue, do you need to keep it?</h3>

                    <h3 v-else class="dialog_normal_question-head">Save As</h3>
                    <div class="SaveDialog_input">
                        <el-input id="ceb3e3" v-model="templateName" placeholder="Please type your file name">
                        </el-input>
                    </div>

                </div>
            </div>
            <div class="  " style=" display: flex ;justify-content: center;">
                <div>
                    <button class=" btn_exportyellow riskButton"
                        style="background-color: #E7E5FF;color:#8E86DD;margin-right: 25px;" @click="closeSaveDialog">
                        Cancel
                    </button>
                    <button  v-if="$parent.isNewQuestion" class=" btn_exportyellow riskButton" 
                        style="margin-right: 25px;" @click="Nosave()">
                        No Save
                    </button>
                     <button :disabled="templateName.length==0" class=" btn_exportyellow riskButton" @click="SaveInputName()">
                        Confirm
                    </button>
                </div>
            </div>
        </el-dialog>
        <SearchLeads :suoClose="SearchLeadsSuo" @closeCloseDialog="clossuoDialog($event)"></SearchLeads>
    </div>

</template>
<script>
import _ from 'lodash';
import { mixins1 } from "@/mixins/index";
import SearchLeads from "../SearchLeads.vue";
import { searchCompany, } from "@/api/searchApi.js";
import {

    saveLeadsPostFilter

} from "@/api/companyApi.js";
import { saveLeadsAnswers, getLeadsQuestions, getLeadsProductFilterList, updateLeadsAnswersHead } from "@/api/userApi";
import { jwtDecode } from 'jwt-decode';

export default {
    mixins: [mixins1],
    components: {
        SearchLeads,
    },

    name: "",
    props: {
        CompanyQuestionShow: {
            type: Boolean
        }
    },

    watch: {
        '$store.state.app.leadesTags': {
            handler(newVal, oldVal) {
                this.SavedAnswersList = newVal
                // 在这里处理columns属性的变化

            },
            deep: true, // 深度监听，可以监听对象内部属性的变化
            immediate: true, // 立即执行一次handler函数，即使columns没有变化
        },

        CompanyQuestionShow: {
            handler(newVal, oldVal) {

                if (newVal) {
                    if(this.$parent.token){
                        this.getListsaveLeadsAnswers()

                    }
                }

                // 在这里处理Sort属性的变化
            },
            deep: true, // 深度监听，可以监听对象内部属性的变化
            immediate: true, // 立即执行一次handler函数，即使columns没有变化
        },

    },
    mounted() {

        this.$store.dispatch("app/setThemColor", "#8E86DD");
        this.$store.dispatch("app/setSearchType", "risk");
    },
    data() {
        return {
            trigger: "manual",
            SaveDialog: false,
            questionWentiDialog: true,
            statesCountryarr: '',
            questionDialog: false,
            objectTypeValue: [],
            activeNames: ['0'],
            templateName: '',
            QuestionPageList: [],
            QuestionPageListON: [],
            arrSearch: [],
            objectTypeoptions: [],
            statesCountry: [],
            SearchLeadsSuo: true,
            quesitonList: [],
            CompanyCountryoptions: [],
            Companyoptions: [],
            Companyvalue: ['APPLE', 'COLFAX CORPORATION'],
            competitorsCompanyvalue: ['APPLE', 'COLFAX CORPORATION', 'HUAWEI'],
            CompanyCountryvalue: ['China'],
            list: [],
            loading: true,
            states: [],
            currentIndex: 0,
            index: -1,
            percentage: 34,
            carouselItems: ["", "", "", "", "", ""],
            roleQuestion: [],
            targetingradio: "1",
            SavedAnswerIndexActive: undefined,
            checkList: [],
            SavedAnswersList: [],
            cascaderProps: {
                // 这里设置最后一层可以多选
                multiple: false,
                checkStrictly: false, // 允许选择不严格
                emitPath: false, // 不发出路径
                label: 'optionsContent',  // 显示的标签
                value: 'id',     // 选中的值
                children: 'list',
                // 其他属性可以根据需要添加
            },

            urposeValue: "",
            qualificationsValue: '',
            audienceValue: '',
            lengthValue: '',
            enterpriseValue: '',
            creditValue: '',
            selectValue: '',
            sizeValue: '',
            resquestion: {},
            keyword: ''

        };
    },

    methods: {
        handleactiveNamesChange(val){
            if(this.$parent.PCMoudele[3].hasPrivilege){
                this.activeNames=val
            }else{
                this.activeNames=['0']
                this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！')
            }
            //
            // 
        },
        highlightKeyword(text) {

            if (!this.keyword) {
                return text
            }
            const regex = new RegExp(this.keyword, 'gi')
            return text.replace(regex, match => `<span class="highlight">${match}</span>`)
        },
        clossuoDialog(value) {
            this.SearchLeadsSuo = value;
        },
        cascaderflase() {
            // this.$refs.cascader[0].dropDownVisible=false
        },
        clickOutSide() {

            // this.$refs.cascader[0].dropDownVisible=false

            //  
            if (this.$refs.upSelectwo) {
                this.$refs.upSelectwo.forEach(item => {
                    item.visible = false
                })
            } if (this.$refs.upSelectduoxuanSearch) {
                this.$refs.upSelectduoxuanSearch.forEach(item => {
                    item.visible = false
                })
            }
            if (this.$refs.upSelect) {
                this.$refs.upSelect[0].visible = false

            }
            if (this.$refs.upSelectduoxuan) {
                this.$refs.upSelectduoxuan[0].visible = false

            }

        },
        clickOutSideTwo(index) {

        },
        Finish() {
            this.quesitonList = []
            this.questionDialog = true
            this.QuestionPageList.forEach((item, index) => {
                if (item.selectedCodes != null) {
                    if (Array.isArray(item.selectedCodes)) {
                        if (item.selectedCodes.length > 0) {
                            if (item.leadsQuestions.type == 9) {
                                this.quesitonList.push({
                                    value: (item.selectedCodes.map(item => { return item.optionsContent })).join(',')
                                    ,
                                    label: item.leadsQuestions.questionContent,
                                })

                            } else {
                                this.quesitonList.push({
                                    value: (item.leadsQuestions.type == 7 || item.leadsQuestions.type == 5) ? item.selectedCodes : (item.selectedCodes.map(item => { return item.optionsContent })).join(',')
                                    ,
                                    label: item.leadsQuestions.questionContent,
                                })
                            }

                        }
                    } else {


                        this.quesitonList.push({
                            value: (item.leadsQuestions.type == 7 || item.leadsQuestions.type == 5) ? item.selectedCodes : item.selectedCodes.optionsContent,
                            label: item.leadsQuestions.questionContent,
                        })
                    }

                }

                // else {

                //     if (item.selectedCodes != null) {
                //         this.quesitonList.push({
                //             value: item.selectedCodes[0].optionsContent,
                //             label: item.selectedCodes.questionContent,
                //         })
                //     }
                // }
            })
        },
        leadsQuestionschange(value) {
            let arrQusetion = this.QuestionPageList.map(item => {
                return { ...item, selectedOptions: item.selectedCodes == null ? null : JSON.stringify(item.selectedCodes) };
            });
            getLeadsQuestions({
                leadsAnswersHead: {
                    "id": null,
                    "userId": null,
                    "title": null,
                    "creatTime": null,
                    "updateTime": null
                },
                leadsQuestionsVOList: arrQusetion

            }).then(res => {
                if (res.code == 200) {
                    this.QuestionPageList = res.data.leadsQuestionsVOList

                    this.QuestionPageList.map((item, index) => {

                        item.selectedCodes = item.selectedCodes==null?JSON.parse(item.selectedCodes): item.selectedCodes
                        item.showCode = item.selectedCodes == null ? null : item.selectedCodes.id

                    })


                }

            })
            // let arr2 = []
            // arr2.push(value)
            // this.QuestionPageList[1].selectedOptions = arr2
            // this.QuestionPageList[1].showCode = value
        },
        cleanEmptyLists(options) {
            return options
                .map(option => {
                    // 递归清理子选项
                    const cleanedChildren = this.cleanEmptyLists(option.list);
                    // 只保留非空的子选项，若为空则置为 null
                    return {
                        ...option,
                        list: cleanedChildren.length > 0 ? cleanedChildren : null // 修改这里
                    };
                })
            // .filter(option => option.list !== null); // 只保留 list 不为 null 的选项
        },
        getListsaveLeadsAnswers() {
            getLeadsQuestions({

            }).then(res => {
                if (res.code == 200) {
                    this.resquestion = res
                    this.QuestionPageList = res.data.leadsQuestionsVOList
                    this.QuestionPageListON = res.data.leadsQuestionsVOList
                    this.QuestionPageList.map(item => {

                        item.showCode = null
                    })

                    // this.QuestionPageList[0].leadsOptions.forEach(item=>{
                    //    if( item.list.length>0){
                    //     item.list.forEach(ite=>{
                    //         ite.list=null
                    //     })
                    //    }
                    // })
                }

            })

        },
        // ShowResult自己展示
        ShowResult() {
            this.$parent.isNewQuestion=true
            this.questionWentiDialog = false
            this.$parent.CompanyQuestionShow = false
            this.questionDialog = false
            this.$emit('getPantentList', this.QuestionPageList)
  
            // this.SaveDialog = false
        },
        //    输入保存问题
        SaveInputName() {
          
            if (this.$parent.SaveAnswer && this.$store.state.app.SeachLabels != '') {
                let userinfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;
                let arrFileter = this.$parent.ComanyFilterList.map((item, index) => {
                    if (Array.isArray(item.selectedCodes) && item.selectedCodes.length > 0) {
                        item.selectedOptions = item.leadsOptions.filter((ite) => { return ite.optionsCode == item.selectedCodes[0] })

                    }
                    return { ...item, }
                });

                arrFileter.map(item => {
                    item.selectedOptions = item.selectedOptions == null ? null : JSON.stringify(item.selectedOptions[0])
                })

                saveLeadsPostFilter({

                    leadsAnswersHead: {
                        id: this.$store.state.app.SeachLabels,
                        userId: userinfo.id,
                        title: this.templateName,
                        creatTime: null,
                        updateTime: null,
                    },
                    leadsQuestionsVOList: arrFileter
                }).then(res => {
                })
                updateLeadsAnswersHead({
                    leadsAnswersHead: {
                        id: this.$store.state.app.SeachLabels,
                        userId: userinfo.id,
                        title: this.templateName,
                        creatTime: null,
                        updateTime: null,

                    },
                }).then(res => {
                    if (res.code == 200) {
                        this.SaveDialog = false
                        this.$parent.$parent.$parent.getCommonPrivilegeModule()
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: "success",
                        });
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: "error",
                        });
                    }
                })

            } else {
                let arrQusetion = this.QuestionPageList.map((item, index) => {
                    if (Array.isArray(item.selectedCodes)) {
                        if (item.selectedCodes.length > 0) {
                            item.selectedCodes.map(ite => {
                                ite.list = null
                            })
                        } else {
                            item.selectedCodes = null
                        }

                    }
                    return { ...item, selectedOptions: (item.selectedCodes == null) ? null : JSON.stringify(item.selectedCodes), selectedCodes: item.selectedCodes };
                });


                let userinfo = JSON.parse(localStorage.getItem('userInfo')).userInfo;
                saveLeadsAnswers({
                    leadsAnswersHead: {
                        id: null,
                        userId: userinfo.id,
                        title: this.templateName,
                        creatTime: null,
                        updateTime: null,

                    },
                    leadsQuestionsVOList: arrQusetion
                }).then(res => {
                    if (res.code == 200) {

                        this.$parent.$parent.$parent.getCommonPrivilegeModule()
                        this.$store.dispatch("app/SetSeachLabels", res.data.leadsAnswersHead.id);
                        this.$store.dispatch("app/SetSeachLabelsCodeName", res.data.leadsAnswersHead.title);

                        // this.$store.commit("setRefreshKey", new Date().getTime());
                    } else {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: "error",
                        });
                    }
                })
                this.SaveDialog=false
                this.$parent.isNewQuestion=false

                this.questionWentiDialog = false
            this.$parent.CompanyQuestionShow = false
            this.questionDialog = false
                // this.ShowResult()

            }



        },
        // 保存问题弹框
        Savemyanswers() {
            if(this.$parent.PCMoudele[5].hasPrivilege){
                this.SaveDialog = true
            }else{
                this.$bus.$emit('show-permission-dialog', '您没有权限访问该功能！')
            }
            // if(this.$parent.PCMoudele){

            // }
            // 
        },
        // 关闭保存弹框
        closeSaveDialog() {
            this.SaveDialog = false

        },
        Nosave(){
            this.SaveDialog = false
            this.$parent.isNewQuestion=false
        },
        //关闭弹框
        closeDialog() {
            this.questionDialog = false
        },
        goToNextSlide1() {
            this.percentage = this.percentage - 33;
            this.$refs.carousel.prev();
        },
        // 模糊 搜索
        competitorsremoteMethod(query) {
            if (query !== "") {

                    this.loading = false;
                    this.Companyoptions = this.states.filter((item) => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
            } else {
                this.Companyoptions = [];
            }
        },
        // 公司名称模糊搜索
        debouncedRemoteMethodCompany: _.debounce(function (query) {
            this.loading = false
            this.remoteMethodSeachCompany(query, 6);
        }, 600), // 300毫秒延迟
        debouncedRemoteMethodCompany3: _.debounce(function (query) {
            this.loading = false
            this.remoteMethodSeachCompany(query, 7);
        }, 600), // 300毫秒延迟
        async remoteMethodSeachCompany(query, page) {
  
            if (query !== "") {
                this.loading = true;
                this.keyword = query
               
                let params = {
                    company_name: query,
                    page: 1,
                    pageSize: 100,

                };
                //    'company_name=' + this.searchName + '&company_country=' + this.searchCode + '&page=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&province_en=' + this.province + '&city_en=' + this.city + '&isic_code=' + this.industryCheck + '&company_class_code=' + this.companyTypeCheck + '&searchType=' + this.keywords + '&scale_code=' + this.companySizeCheck + '&reg_status=' + this.companyStatusCheck + '&listing_status_code=' + this.listedCompanyCheck + '&years=' + this.timePeriodCheck
                let res = await searchCompany(params);

                let parentTable = [];
                if (res.code==200) {


                    let list = (parentTable = res.data.rows);

                    //查找本地图片
                    list = list.map((item, index) => {
                        let countryImg = this.getCountryImgUrl(item.company_country);
                        if (countryImg) {
                            let flag = require("../../../../../static" + countryImg);
                            parentTable[index].flag = flag;

                        }
                    });
                    let index = this.QuestionPageList.findIndex(item => item.leadsQuestions.type == 9 && item.leadsQuestions.code == page);
                    let seachCompanyList = []
                    seachCompanyList = parentTable.map(item => {
                        return { optionsCode: item.aaa_id, optionsContent: item.company_name_en, flag: item.flag, aaa_id: item.aaa_id };
                    });
                    this.QuestionPageList[index].leadsOptions = seachCompanyList

                    this.loading = false;

                } else {
                    // this.addPopIndex2=true
                    if (res.code == "1002") {
                        this.$message({
                            message: res.msg,
                            center: true,
                            type: "error",
                        });
                    } else {
                        this.loading = false;
                        // this.parentTable = [];

                    }
                }
            } else {
                // this.loading = false;
                this.Companyoptions = [];
            }
        },
        debouncedRemoteMethod: _.debounce(function (query) {
            this.loading = true;
            this.remoteMethod(query, 3);
        }, 1000), // 300毫秒延迟
        debouncedRemoteMethod3: _.debounce(function (query) {
            this.loading = true;
            this.remoteMethod(query, 12);
        }, 1000),
        // 模糊 搜索
        remoteMethod(query, page) {
            this.loading = true;

            if (query !== "") {
                this.keyword = query
       
                getLeadsProductFilterList({
                    // isicCode: this.QuestionPageList[0].selectedCodes.optionsCode,
                    productNameDesc: query,
                    pageSize: 100
                }).then(res => {
                  if(res.code==200){
                    this.loading = false;
                    setTimeout(() => {
                    let index = this.QuestionPageList.findIndex(item => item.leadsQuestions.type == 8);
                    this.QuestionPageList[index].leadsOptions = res.data
                }, 200);
                  }

                })
            } else {
                this.Companyoptions = [];
            }
        },
        // 模糊 搜索国家
        remoteMethodCountry(query) {
            if (query !== "") {
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.CompanyCountryoptions = this.statesCountry.filter((item) => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.CompanyCountryoptions = [];
            }
        },
        // 左侧如果有保存条件
        SavedAnswerIndex(item, index) {
            this.SavedAnswerIndexActive = index;
            this.currentIndex = 0;
            this.percentage = 34;
            this.$nextTick(() => {
                this.$refs.carousel.setActiveItem(0);
            })

            let userinfo = JSON.parse(localStorage.getItem("userInfo")).userInfo;

            getLeadsQuestions({
                leadsAnswersHead: {
                    id: item.id,
                    userId: userinfo.id,
                    title: null,
                    creatTime: null,
                    updateTime: null,
                },
            }).then((res) => {
                if (res.code == 200) {
                    this.QuestionPageList = res.data.leadsQuestionsVOList

                    this.QuestionPageList.map((item, index) => {
                        item.selectedCodes = JSON.parse(item.selectedOptions);
                        if (item.leadsQuestions.type == 9) {
                            item.leadsOptions = item.selectedCodes
                        }
                        if (index == 0) {

                            item.showCode = Number(item.selectedCodes.id);
                        }



                    })

                    // this.CompanyQuestionList = res.data.leadsQuestionsVOList.filter(
                    //     (item) => item.selectedOptions != null
                    // );
                    // this.CompanyQuestionList.map((item, index) => {
                    //     item.selectedCodes = JSON.parse(item.selectedOptions);
                    //     if (index == 0) {
                    //         item.showCode = Number(item.selectedCodes.id);
                    //     }
                    // });



                }
            });
        },
        handleChange(value) {

            let arr = null
            this.QuestionPageList[0].leadsOptions.forEach(item => {
                item.list.forEach(ite => {
                    if (ite.list != null) {
                        ite.list.forEach(it => {
                            if (it.id == value) {
                                arr = it

                            }
                        })

                    }

                })
            })
            this.QuestionPageList[0].selectedOptions = JSON.stringify(arr)
            this.QuestionPageList[0].selectedCodes = JSON.stringify(arr)
            let arrQusetion = this.QuestionPageList
            // this.QuestionPageList[1].selectedCodes = []


            getLeadsQuestions({
                leadsAnswersHead: {
                    "id": null,
                    "userId": null,
                    "title": null,
                    "creatTime": null,
                    "updateTime": null
                },
                leadsQuestionsVOList: arrQusetion

            }).then(res => {
                if (res.code == 200) {
                    this.QuestionPageList = res.data.leadsQuestionsVOList

                    this.QuestionPageList.map((item, index) => {

                        item.selectedCodes = JSON.parse(item.selectedCodes)
                        item.showCode = item.selectedCodes == null ? null : item.selectedCodes.id

                    })


                }

            })
        },
        handleChangetwo(value) {
            let index = this.QuestionPageList.findIndex(item => item.leadsQuestions.id == 11);
            let arr = null
            this.QuestionPageList[index].leadsOptions.forEach(item => {
                item.list.forEach(ite => {
                    if (ite.list != null) {
                        ite.list.forEach(it => {
                            if (it.id == value) {
                                arr = it

                            }
                        })

                    }

                })
            })
            this.QuestionPageList[index].selectedOptions = JSON.stringify(arr)
            this.QuestionPageList[index].selectedCodes = arr
     
        },
        handleCarouselChange(index) {
            this.currentIndex = index;
        },
        // 下一页
        goToNextSlide(index) {
            if (index == 0) {


                let arrQusetion = this.QuestionPageList.map(item => {
                    return { ...item, selectedOptions: item.selectedCodes == null ? null : JSON.stringify(item.selectedCodes) };
                });

                getLeadsQuestions({
                    leadsAnswersHead: {
                        "id": null,
                        "userId": null,
                        "title": null,
                        "creatTime": null,
                        "updateTime": null
                    },
                    leadsQuestionsVOList: arrQusetion

                }).then(res => {
                    if (res.code == 200) {
                        this.QuestionPageList = res.data.leadsQuestionsVOList

                        this.QuestionPageList.map((item, index) => {

                            if (item.leadsQuestions.id == 5 && item.selectedOptions == null) {
                                item.selectedCodes = []
                            }
                            if (item.leadsQuestions.type == 8 && item.selectedOptions != null) {
                                item.leadsOptions = [item.selectedCodes]
                            }
                            item.showCode = item.selectedCodes == null ? null : item.selectedCodes.id
                        })



                    }

                })
            }
            if (index === this.currentIndex) {
                this.percentage = this.percentage + 33;
                this.$refs.carousel.next();
            }
        },
    },
    computed: {},
};
</script>
<style scoped>
.leads-head {
    height: 18.285714285714285rem;
    width: 100%;
    background: #8E86DD;
    padding: 1.8571428571428572rem 1.4285714285714286rem;
}

.leads-head-title {
    margin-left: 0.7142857142857143rem;
    font-family: DMSans, DMSans;
    font-weight: 400;
    font-size: 2.2857142857142856rem;
    color: #ffffff;
    height: 3rem;
    line-height: 3.2857142857142856rem;
}

.leads-head-titleTwo {
    margin-left: 0.7142857142857143rem;
    height: 2.142857142857143rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 1.2857142857142858rem;
    color: #ffffff;
    line-height: 2.142857142857143rem;
    margin-top: 0.7142857142857143rem;
}

.leads-content {
    position: relative;
    /* top: -8.428571428571429rem; */
    z-index: 990;
    /* padding: 1.4285714285714286rem; */
    height: 100%;
    background: #F5F5F5;
    border-radius: 6px;
}

.leads-flex {
    display: flex;
    height: 98%;
    /* overflow-y:scroll ; */
}

.leads-content-left {
    width: 48.57142857142857rem;
    max-height: 55.57142857142857rem;
    margin-right: 0.7142857142857143rem;
    background: #ffffff;
    box-shadow: 0rem 0.14285714285714285rem 1rem 0rem rgba(0, 0, 0, 0.12);
    border-radius: 0.2857142857142857rem;
    text-align: center;
    padding-top: 2.857142857142857rem;
    position: relative;
}

.leads-content-right {
    flex: 1;
    height: 55.57142857142857rem;
    overflow: hidden;
    /* height: 225%; */
    background: #F5F5F5;
    border-radius: 6px;
    text-align: center;
    max-height: 39.57142857142857rem;
    overflow-y: auto;
    padding: 1.4285714285714286rem
}

.leads-content-left-text {
    font-family: DMSans, DMSans;
    font-weight: 400;
    font-size: 1.2857142857142858rem;
    color: #666666;
    line-height: 1.8571428571428572rem;
    text-align: left;
    width: 56%;
    margin: 2.142857142857143rem auto;
}

.leads-content-left-jiantou {
    position: absolute;
    bottom: 3.857142857142857rem;
    right: 4.857142857142857rem;
}

.leads-content-right-content {
    /* width: 58%; */
    margin: 0 auto;
    text-align: center;
    height: 100%;
}

.question-step-question {
    font-weight: 400;
    font-size: 1.4285714285714286rem;
    color: #1a2332;
    min-height: 1.85714286rem;
    line-height: 1.85714286rem;
    margin-bottom: 10px;
    display: flex;
    font-family: 'DMSans Bold';
}

/* .question-step-question  /deep/ .el-input__icon {
    width: 1.5rem;

} */
.question-step-con {
    margin-bottom: 1.42867143rem;
}

.bitian {
    color: #f56b6b;
    font-size: 1.7142857142857142rem;
    margin-right: 0.7142857142857143rem;
}

.btn_exportyellow {
    min-width: 12.857142857142858rem;
    height: 2.857142857142857rem;
    background: #8E86DD;
    border-radius: 0.7142857142857143rem;
    padding: 0.5rem;
    font-size: 1.2857142857142858rem;
    color: #fff;
    /* line-height: 2.857142857142857rem; */
}

.question_bottom .btn_exportyellow {

    font-size: 1.1428571428571428rem;
    color: #fff;
}

.leads-content-right /deep/ .el-cascader {
    width: 100%;
}

.leads-content-right /deep/ .el-cascader-node__label {
    font-size: 1.2857142857142858rem;
    color: #1a2332;
}

.leads-content-right /deep/ .el-carousel__container {
    height: 100%;
}

.OptionalQuestions {
    font-weight: 400;
    font-size: 1.2857142857142858rem;
    color: #1290c9;
    height: 1.5714285714285714rem;
    margin-bottom: 1.4285714285714286rem;
}

.leads-content-right /deep/ .el-progress__text {
    display: none;
}

.progress {
    width: 34.285714285714285rem;
    text-align: center;
    margin: auto;

    margin-bottom: 3.5714285714285716rem;
}

.progressnum {
    height: 1.4285714285714286rem;
    font-size: 1.0714285714285714rem;
    color: #999999;
    margin-bottom: 1.1428571428571428rem;
}

.leads-content-right /deep/ .el-collapse-item__header {
    height: auto;
    line-height: normal;
    border: none !important;
    width: 14.285714285714286rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2857142857142rem;
color: #1290C9;
background-color: #f5f5f5;
margin-bottom: 1.42867143rem;
}

.leads-content-right /deep/ .el-collapse {
    border: none;
}

.leads-content-right /deep/.el-carousel--horizontal {
    height: 100%;
}

.leads-content-right /deep/ .el-collapse-item__arrow {
    /* margin: -1.0714285714285714rem 0.5714285714285714rem 0 auto; */
    color: #1290c9;
}

.leads-content-right /deep/ .el-radio.is-bordered {
    background: #ffffff;
    border-radius: 0.7142857142857143rem !important;
    border: 0.07142857142857142rem solid #cccccc !important;
}

.leads-content-right /deep/.el-radio.is-bordered {
    width: 49%;
    height: 4.142857142857143rem;
    justify-content: flex-start;
    margin-right: 0rem !important;
    padding: 0rem;
    padding-left: 1.4285714285714286rem;
}

.leads-content-right /deep/ .el-radio.is-bordered.is-checked {
    border: 0.07142857142857142rem solid #8E86DD !important;
}

.leads-content-right /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #8E86DD;
}

.leads-content-right /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    background: #8E86DD;
}

.leads-content-right /deep/ .el-radio__label {
    font-size: 1.2857142857142858rem;
    color: #1a2332;
}

.leads-content-right /deep/ .el-collapse-item__content {
    padding-bottom: 0rem !important;
}

.leads-content-left-Savelist {
    margin-top: 0.7142857142857143rem;
    text-align: center;
}

.SavedAnswers-title {
    height: 2.857142857142857rem;
    font-family: DMSans, DMSans;
    font-weight: 400;
    font-size: 2.142857142857143rem;
    color: #1a2332;
    text-align: center;
    margin-bottom: 2.142857142857143rem;
}

.SavedAnswers-list {
    height: 2.857142857142857rem;
    background: #f5f5f5;
    border-radius: 0.7142857142857143rem;
    padding-left: 2.142857142857143rem;
    width: 80%;
    margin: auto;
    line-height: 2.857142857142857rem;
    text-align: left;
    margin-bottom: 0.7142857142857143rem;
    font-size: 1.2857142857142858rem;
    color: #1a2332;
    cursor: pointer;
}

.SavedAnswers-listActive {
    position: relative;
    background: #8E86DD;
    color: #ffffff;
    cursor: pointer;
}

.right-icon {
    position: absolute;
    right: -1.0714285714285714rem;
    top: 32%;
}

.leads-content-right /deep/ .el-tag.el-tag--info .el-tag__close {
    color: #ffffff;
    background-color: #f56b6b;
}

.leads-content-right /deep/ .el-tag.el-tag--info {
    height: 2.857142857142857rem;
    background: #ffffff;
    border-radius: 1.6428571428571428rem;
    border: 0.07142857142857142rem solid #cccccc;
    font-size: 1.2857142857142858rem !important;
    color: #1a2332;
}

.-dropdown__item {
    font-size: 1.2857142857142858rem !important;
    color: #1a2332;
    /* line-height: 35px !important; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-bottom: 5px;
    white-space: normal;

}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #8E86DD !important;
}

.question-detail_cont {
    width: 52.857142857142854rem;
    height: 42.42857142857143rem;
    background: #F5F5F5;
    border-radius: 0.42857142857142855rem;
    overflow-y: auto;
    padding: 1.4285714285714286rem;
    margin-top: 0.7142857142857143rem;
}

.question-detail_cont-list {
    margin-bottom: 2.142857142857143rem;
}

.question-detail_cont-list p {
    font-family: DMSans, DMSans;
    font-weight: 400;
    font-size: 1.2857142857142858rem;
    color: #666666;
    height: 1.7142857142857142rem;
    line-height: 1.7142857142857142rem;
}

.question-detail_cont-list h3 {
    margin-top: 0.7142857142857143rem;
    font-family: DMSans, DMSans;
    font-weight: 400;
    font-size: 1.2857142857142858rem;
    color: #1A2332;
    /* height: 1.7142857142857142rem;
    line-height: 1.7142857142857142rem; */
}

.question_bottom {
    padding-top: 1.4285714285714286rem;
    display: flex;
    justify-content: space-between;
}

.SaveDialog_input {
    margin: 2.142857142857143rem 0rem;
}

.SaveDialog_input /deep/ .el-input__inner {
    height: 3.5714285714285716rem;
    background: #FFFFFF;
    border-radius: 0.5714285714285714rem;
    border: 0.07142857142857142rem solid #CCCCCC;
    font-size: 1.4285714285714286rem;
}

/* /deep/ .el-select-dropdown {
    left: 0px !important;
    width: 100%;

} */

/deep/ .el-select-dropdown__item {
    height: auto !important;
    text-align: left;

}

/deep/ .el-popper {
    z-index: 99 !important;
}

/deep/ .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #CCCCCC !important;
}

/deep/ .highlight {
    color: #f56c6c;
    font-weight: bold;
}

.Country_img {
    height: 1.4rem;
    width: 2rem;
    margin-right: 0.8rem;
}

.Country_name {
    line-height: 1.6rem !important;
    font-size: 1.5rem;
}

.Search_Company {
    margin-bottom: 0.5rem;
    height: 3rem;
}

.question-step-con /deep/ .el-select__tags {
    /* padding-left: 1.42857143rem; */
}


/deep/ .el-collapse-item__wrap{
    background-color: #f5f5f5 !important;
}
button:disabled {
    cursor: no-drop !important;
    background-color: #EEEEEE !important;
    color: #999999;
}
.el-saech-eye{
    position: absolute;
    right: 0.7142857142857143rem;
    top: 0px; 
    color: #1A2332;
    font-size: 1.2857142857142rem;
}
/deep/ .el-select__input{
    margin-left: 10px ;
    font-size: 1.2857142857142rem !important;
}
</style>
