<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true" @close="closeDialog"
            custom-class="dialog_normal_question dialog_nrmore_radius" :append-to-body="true">
            <div >
                <div class="detail_cont">
                    <h3 class="dialog_normal_question-head margin-bottom20">Update for more recommendations</h3>
                    <div class="Exportoffline">


                        <div class="detail_cont_text" style="padding: 0px;margin-top: 0px !important;">
                            <h3 class="margin-bottom20">For more details and/or recommendations about your potential
                                customers or vendors, you can select the following options:</h3>
                            <div class="ExportCheckbook">
                                <el-radio v-model="Updatechecked" label="1">Order comprehensive reports</el-radio>

                            </div>
                            <div class="ExportCheckbook-text">
                                This comprehensive report will encompass all the necessary background information,
                                including detailed contact details, trade
                                records, a business description, company performance, credit rating and more.

                            </div>
                            <div class="ExportCheckbook">
                                <el-radio v-model="Updatechecked" label="2" :disabled="true">Contact the selected companies (Coming soon...) </el-radio>
                            </div>
                            <div class="ExportCheckbook-text">
                                This service offers a list of multiple valid email addresses for your target companies.
                                You can use these addresses to send inquiries to the target companies via email. Once
                                the target companies respond to your inquiries, the valid contact information will be
                                established.

                            </div>
                            <div class="ExportCheckbook">
                                <el-radio v-model="Updatechecked" label="3">Generate more leads</el-radio>
                            </div>
                            <div class="ExportCheckbook-text">
                                This service provides more information about potential customers or vendors that align with your requirements. You have the flexibility to select the number of companies, the frequency of the reports, and the preferred method of receiving them.

                            </div>
                        </div>
                        <div>
                            <!-- <h3>Please select the type of offline report to update :</h3> -->
                            <!-- <div class="ExportCheckbook">
                            <el-checkbox v-model="Updatechecked">Update existing offline reports</el-checkbox>
                        </div>
                        <div class="ExportCheckbook">
                            <el-checkbox v-model="reportschecked">Download offline reports with precise
                                data</el-checkbox>

                        </div> -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex_center margin-top20">
                <el-button class="btn_export riskButton riskButton" style="background-color: #E7E5FF !important; color: #8E86DD !important;"
                    @click="closeDialog()">Cancel</el-button>
                <el-button class="btn_export riskButton riskButton" :class="false ? 'questionExportButton' : 'questionExportButtonactive'"
                    @click="UpdateNext()">Next</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="UpdateEndDialog" center :close-on-click-modal="true" @close="UpdatecloseDialog"
            custom-class="dialog_normal_question dialog_nrmore_radius" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class=" dialog_normal_question-head" > Order comprehensive reports</h3>
                    <div class="detail_cont_text ExportCheckbook-center" style="margin-top: 10px;">
                        This data needs to be completed offline within two to three days and will be sent to your email after completion 
                    </div>
                    <!-- <div class="detail_cont_texttwo flex_box" style="padding: 0px;" v-if="Updatechecked">
                        <div style="text-align: left;color: #1A2332;">Update existing offline
                            reports</div>
                        <div style="margin-left: 30px;">
                            After clicking confirm, the offline report of the selected company will be sent to your
                            email
                        </div>
                    </div>
                    <div v-if="reportschecked" class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div style="text-align: left;width: 290px;color: #1A2332;">Update offline reports with precise
                            data</div>
                        <div style="margin-left: 30px;">
                            This data needs to be completed offline within two to three days and will be sent to your
                            email after completion
                        </div>
                    </div> -->

                </div>
            </div>
            <div class="flex_center" style="margin-top: 10px;">
                <el-button class="btn_export questionExportButtonactive riskButton" @click="UpdatecloseDialog()">Previous
                    Step</el-button>
                <el-button class="btn_export riskButton" @click="UpdateEndDialog = false; closeDialog()"
                    style="background-color: #E7E5FF !important; color: #8E86DD !important;margin-left: 30px;">Cancel</el-button>

                <el-button class="btn_export questionExportButtonactive riskButton" @click="ConfirmUndate()">Confirm</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="uPdateDialog" center :close-on-click-modal="true" @close="uPdatecloseDialog"
            custom-class="dialog_normalsuo dialog_nrmore_radius" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class=" dialog_normal_question-head">Update</h3>
                    <div class="detail_cont_text" style="padding: 0px;margin-top: 30px;">
                        You need to deduct {{ this.$store.state.app.changeList.length }} credits when “Update existing offline reports “. Click “ OK “ to continue update
                    </div>

                </div>
            </div>
            <div class="flex_center" style="margin-top: 30px;">
                <el-button class="btn_export questionExportButtonactive riskButton" @click="onSubmitkoudian()">OK</el-button>
                <el-button class="btn_export questionExportButtonactive riskButton" style="margin-left: 30px;"
                    @click="onSubmitquxiao()">Add Credits</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="UpdateEndDialogtree" center :close-on-click-modal="true" @close="UpdatecloseDialogtree"
            custom-class="dialog_normal_question dialog_nrmore_radius" :append-to-body="true">
          <div class="leads-content-right">

        
            <div class="leads-content-right-content">
                <div class="detail_cont">
                    <h3 class=" dialog_normal_question-head" > Generate more leads</h3>
           
                    <div class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div  style="text-align: left;width: 167px;color: #1A2332;">Recommended target number:</div>
                        <div style="width: 77%;">
                            <el-select  :popper-append-to-body="false"  style="width: 100%;" v-model="NumberValue" placeholder="Please select the Recommended target number">
                                <el-option
                                v-for="item in NumberValueList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div  class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div style="text-align: left;width: 167px;color: #1A2332;">Recommended frequency:</div>
                        <div style="width: 77%;">
                            <el-select style="width: 100%;"  :popper-append-to-body="false"  v-model="frequeryListvalue" @change="SelectQuerlsit()" placeholder="Please select the recommended frequency">
                                <el-option
                                v-for="item in frequeryList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div  class="detail_cont_texttwo flex_box" style="padding: 0px;" v-if="isSelectduoxuan">
                        <div style="text-align: left;width: 167px;color: #1A2332;">Recommended deadline:</div>
                        <div style="width: 77%;">
                            <el-select  :popper-append-to-body="false"  style="width: 100%; "  v-model="duxuanvaluetwo" placeholder="Please select the deadline for advancement">
                                <el-option
                                v-for="item in duoxuanList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-cascader
                            :popper-append-to-body="false"
                            :append-to-body="true"
                            style="width: 100%;"
                            :options="options"
                            :props="props"
                            clearable></el-cascader> -->
                        </div>
                    </div>
                    <div  class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div style="text-align: left;width: 167px;color: #1A2332;">Recommended content:</div>
                        <div style="width: 77%;">
                            <el-select style="width: 100%; "  :popper-append-to-body="false"  multiple v-model="duxuanvalue" placeholder="Please select recommended content">
                                <el-option
                                v-for="item in duoxuanListone"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            <!-- <el-cascader
                            :popper-append-to-body="false"
                            :append-to-body="true"
                            style="width: 100%;"
                            :options="options"
                            :props="props"
                            clearable></el-cascader> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
            <div class="flex_center" style="margin-top: 30px;">
                <el-button class=" riskButton btn_export questionExportButtonactive" @click="UpdatecloseDialogtree()">Previous
                    Step</el-button>
                <el-button class="btn_export riskButton" @click="UpdatecloseDialogtree()"
                    style="background-color: #E7E5FF !important; color: #8E86DD !important;margin-left: 30px;">Cancel</el-button>
                    <a class="flex_center" :href="'mailto:service@csibizinfo.com?subject=Generate more leadst&body=UserID: '+userinfo.userInfo.email+'%0Aand recommended target number:' +
                 NumberValue +'%0Arecommended frequency:'+frequeryListvalue+'%0Arecommended deadline:'+duxuanvaluetwo+'%0Arecommended content:'+
                 duxuanvalue
                  ">
                  
                  <el-button class="btn_export questionExportButtonactive riskButton" @click="UpdatecloseDialogtree()">Submit</el-button>
              </a>
            
            </div>
        </el-dialog>
        <ShowCompany :suoClose="ShowCompanySuo" @closeSaveDialog="ShowCompanyDialog($event)"></ShowCompany>
        <ShowCompanytwo :suoClose="ShowCompanySuotwo" @closeSaveDialogtwo="ShowCompanyDialogtwo($event)"></ShowCompanytwo>
    </div>
</template>

<script>
import { saveUserConsumptionBatch, } from "@/api/userApi";
import ShowCompany from "./ShowCompany.vue";
import ShowCompanytwo from "./ShowCompanytwo.vue";
export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
        ComapnyName: {
            type: String
        }
    },
    components: {

        ShowCompany,
        ShowCompanytwo
    },

    data() {
        return {
            UpdateEndDialog: false,
            frequeryListvalue:'One time', 
            Updatechecked: '1',
            reportschecked: true,
            isSelectduoxuan:false,
            duxuanvalue:'',
            duxuanvaluetwo:'',
            userinfo: JSON.parse(localStorage.getItem('userInfo')),
            frequeryList:[
            {
                value:'One time',
                label:'One time',
               },
               {
                value:'Monthly',
                label:'Monthly',
               },
               {
                value:'Quarterly',
                label:'Quarterly',
               },

            ],
            RecommendedList:[
            {
                value:'Company Name',
                label:'Company Name',
               },
               {
                value:'Monthly',
                label:'Monthly',
               },
               {
                value:'Quarterly',
                label:'Quarterly',
               },
            ],
            props: { multiple: true },
        options: [
            {
          value: 1,
          label: 'Company Name',
        
        }, {
          value: 17,
          label: 'Company Profile',
      
        }],
            //page: this.currentPage
            uPdateDialog: false,
            ShowCompanySuo: false,
            ShowCompanySuotwo:false,
            UpdateEndDialogtree:false,
            lastUpdateList: [],
            NumberValue:'5',
            NowUpdateList: [],
         
            NumberValueList:[
               {
                value:'5',
                label:'5',
               },
               {
                value:'10',
                label:'10',
               },  {
                value:'15',
                label:'15',
               },
               {
                value:'20',
                label:'20',
               },
            ],
            duoxuanList:[
               {
                value:'3months',
                label:'3 months',
               },
               {
                value:'6months',
                label:'6 months',
               },  {
                value:'9months',
                label:'9 months',
               },
               {
                value:'1year',
                label:'1 year',
               },
            ],
            duoxuanListone:[
               {
                value:'CompanyName',
                label:'Company Name',
               },
               {
                value:'CompanyProfile',
                label:'Company Profile',
               },  {
                value:'Contactinfo',
                label:'Contact info',
               },
               {
                value:'CompanyCreditReport',
                label:'Company Credit Report',
               },
            ],

            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        SelectQuerlsit(val){
            if(this.frequeryListvalue=='Monthly'){
                this.isSelectduoxuan=true
                        this.duxuanvaluetwo=''
                this.duoxuanList=[
               {
                value:'3months',
                label:'3 months',
               },
               {
                value:'6months',
                label:'6 months',
               },  {
                value:'9months',
                label:'9 months',
               },
               {
                value:'1year',
                label:'1 year',
               },
            ]
            }
             else if(this.frequeryListvalue=='Quarterly'){
                this.duxuanvaluetwo=''
                this.isSelectduoxuan=true
                this.duoxuanList=[
               {
                value:'2',
                label:'2',
               },
               {
                value:'3',
                label:'3',
               },  {
                value:'4',
                label:'4',
               },
           
            ]
            }else{
                this.isSelectduoxuan=false
            }
        },
        UpdateNext(){
           if(this.Updatechecked=='1'){
            this.UpdateEndDialog = true
           }else if(this.Updatechecked=='2'){
            this.ShowCompanySuotwo = true
           }else{
            this.UpdateEndDialogtree = true
            
           }
        },
        ConfirmUndate() {
            if (this.Updatechecked && !this.reportschecked) {

                this.confirmClick()
            }
            if (this.reportschecked) {
                this.uPdateDialog =true
                // this.ShowCompanySuo = true
            }
        },
        confirmClick() {
            const recipient = "mailto:service@csibizinfo.com"; // 收件人邮箱
            const subject = "Dear customer, hello:"; // 邮件主题
            const body = "I hope to receive more customer offline reports. Can sales contact me to discuss and obtain the necessary information. "; // 邮件内容
            const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink; // 调起邮箱
        },
        PreviousStep() { },
        ShowCompanyDialog(value) {
            this.ShowCompanySuo = value;
        },
        ShowCompanyDialogtwo(value) {
            this.ShowCompanySuotwo = value;
        },
        uPdatecloseDialog() {
            this.uPdateDialog = false
        },
        UpdatecloseDialog() {
            this.UpdateEndDialog = false
        },
        UpdatecloseDialogtree() {
            this.UpdateEndDialogtree = false
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeExportDialog', false);
        },
        onSubmitkoudian() {
            let Updatelis = []

            this.$store.state.app.changeList.map((item) => {
                Updatelis.push({
                    countryCode: item.company_country,
                    companyName: item.company_name_en,
                    companyAaaid: item.aaa_id

                });
            });
            saveUserConsumptionBatch(Updatelis).then(res => {
                let that = this
                if (res.code == 200) {
                    res.data.map(item => {
                        if (item.consumptionStatus == 1) {
                            that.lastUpdateList.push(item.consumptionPoints)
                        }
                        if (item.consumptionStatus == 2) {
                            that.NowUpdateList.push(item.consumptionPoints)

                        }
                    })

                    this.$message({
                        message: res.msg,
                        type: 'success'
                    })
                    getUserSimpleInfo().then(res => {
                        if (res.code == 200) {
                            this.$store.dispatch("app/setEquityType", res.data.availablePoints);
                        }
                    })
                    this.newDialog = false
                    this.UpdateEndDialog = false

                    if (this.Updatechecked) {

                        this.confirmClick()
                    }


                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    })
                }


            })

            this.uPdateDialog = false;




        },
        onSubmitquxiao() {
            this.$router.push({
                path: '/index/addCredit',
            });



        },
    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 86vh;
    overflow: auto;

}



.Exportoffline {
    background: #F5F5F5;
    border-radius: 6px;
    padding: 1.42857143rem;
    /* margin-bottom: 1.42857143; */
    margin-top: 10px;
    font-size: 1.42857143rem;
    color: #1A2332;
    line-height: 26px;
}

.questionExportButton {
    background: #EEEEEE !important;
    /* border-radius: 10px; */
    font-size: 1.14285714rem;
    color: #999999;
    margin-left: 2.14285714rem;
}



.ExportCheckbook {
    height: 1.7142857rem;
    margin-top: 1.42857143rem;
}

.detail_cont_texttwo {
    font-size: 1.28571429rem;
    color: #666666;
    margin-top: 1.42857143rem;
    word-break: keep-all;
    text-align: justify;
}

/deep/ .el-radio__label,/deep/ .el-input__inner {
    font-size: 1.4285714285714286rem;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    /* background-color: #ED8850 !important; */
    border-color: #8E86DD !important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
    border-color: #8E86DD !important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
    background-color: #8E86DD !important;
}

/deep/ .el-radio__inner:hover {

    border-color: #8E86DD !important;
    /* border-color: #409EFF; */
}

.Exportoffline {
    font-size: 1.42857143rem;
    color: #1A2332;
    line-height: 26px;
}

.ExportCheckbook {
    height: 24px;
    margin-top: 1.42857143rem;
}

/deep/ .el-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/deep/ .el-radio__input.is-checked+.el-radio__label,
/deep/ .el-radio__label {
    /* font-size: 18px; */
    /* font-size: 1.2857142857142858rem; */
    color: #1a2332;
}

/deep/ .el-radio__label {
    font-size: 1.4285714285714286rem;
    font-family: 'DMSans Bold';
}
/deep/ .el-select-dropdown__item.selected {
color: #8E86DD !important;
}
.ExportCheckbook-text {
    padding-left: 27px;
   
    font-size: 1.4285714285714286rem;
    color: #666666;
}
.ExportCheckbook-center {

   
    font-size: 1.4285714285714286rem;
    text-align: center;
    color: #1a2332;;
}
</style>
