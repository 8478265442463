<template>
    <div>
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="true"  @close="closeDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class="dialog_normal_question-head margin-bottom20">Export Data</h3>

                    <div class="Exportoffline">
                        <h3>Please select your export data items:</h3>
                        <div class="ExportCheckbook">
                            <el-checkbox  v-model="Updatechecked" label="1">Export selected company names</el-checkbox>
                        </div>
                        <div class="ExportCheckbook">
                            <el-checkbox  v-model="UpdatecheckedTwo" label="2">Export selected company data</el-checkbox>
                        </div>
                    </div>
                    <div class="Exportoffline" v-if="UpdatecheckedTwo ">
                        <h3>Please select your export data items:</h3>
                        <div class="ExportCheckbook" style="margin-bottom: 20px;">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                @change="handleCheckAllChange">All</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                            </el-checkbox-group>

                        </div>
                    </div>

                </div>
            </div>
            <div class="flex_center" style="margin-top: 20px;">
                <el-button class="btn_export riskButton " style="background-color: #E7E5FF !important; color: #8E86DD !important;"
                    @click="closeDialog()">Cancel</el-button>
                <el-button class="btn_export"
                    :class="checkedCities.length > 0 || Updatechecked ? 'questionExportButtonactive riskButton' : ' questionExportButton riskButton'"
                    @click=Next()>Next</el-button>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="UpdateEndDialog" center :close-on-click-modal="true"  @close="UpdatecloseDialog"
            custom-class="dialog_normal_question" :append-to-body="true">
            <div>
                <div class="detail_cont">
                    <h3 class="dialog_normal_question-head">Export customer acquisition data</h3>
                    <div v-if="Updatechecked" class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div  style="text-align: left;width: 140px;color: #1A2332;">Export selected company names</div>
                        <div style="margin-left: 36px;color: #1290C9;">
                            <download-excel v-if="Updatechecked" :data="DetailsForm" :fields="json_fields" name="Company.xls">
                                <span class=" " @click="exportCompany"> Click to download directly</span>
                </download-excel>
                       
                        </div>
                    </div>
                    <div v-if="UpdatecheckedTwo&&checkedCities.length > 0 " class="detail_cont_texttwo flex_box" style="padding: 0px;">
                        <div style="text-align: left;width: 245px;color: #1A2332;">Export selected company data</div>
                        <div style="margin-left: 20px;">
                            This data needs to be completed offline within two to three days and will be sent to your email after completion
                        </div>
                    </div>

                </div>
            </div>
            <div class="flex_center" style="margin-top: 30px;">
                <el-button class="btn_export questionExportButtonactive riskButton" @click="UpdatecloseDialog()">Previous
                    Step</el-button>
                <el-button class="btn_export riskButton" @click="UpdateEndDialog = false; closeDialog()"
                    style="background-color: #E7E5FF !important; color: #8E86DD !important;margin-left: 30px;">Cancel</el-button>
               
                <el-button  class="btn_export questionExportButtonactive riskButton" 
                    @click="confirmClick()">Confirm</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { saveUserConsumption, exportData } from "@/api/userApi";

export default {
    props: {
        suoClose: {
            type: Boolean,
            //   default: false,
        },
        obj: {
            type: Object,
        },
        update: {
            type: Object,
        },
        ComapnyName: {
            type: String
        }
    },
    data() {
        return {
            json_fields: {
                'Company Name': 'company_name_en',

                Province: 'province_en',
                City: 'city_en',
                CompanyNumber: 'crefo_no',
                'Inc.Yr.': 'incorporation_year',
                Status: 'status_en',
                Type: 'company_class_en',
                Size: 'scale_en',
            },
            DetailsForm: [],
            checkAll: false,
            checkedCities: [],
            cities: ['Registration Record', 'Shareholder Info', 'Financial Data',],
            NextTrue: true,
            isIndeterminate: false,
            UpdateEndDialog: false,
            Updatechecked: false,
            UpdatecheckedTwo:false,
            reportschecked: true,
            uPdateDialog: false,
            token: sessionStorage.getItem('token'),
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.suoClose;
            },
            set() { },
        },

    },
    destroyed() {
        // bus.$off('handleShowLogin');
    },
    methods: {
        Next() {
            this.UpdateEndDialog = true

        },
        // 导出列表
        exportCompany() {
            this.UpdateEndDialog = false
            this.DetailsForm = this.$store.state.app.changeList
        },
        confirmClick() {
       
       if(this.UpdatecheckedTwo&&this.checkedCities.length > 0){
        let Updatelis = []
            this.$store.state.app.changeList.map((item) => {
                Updatelis.push(
                    item.aaa_id
                );
            });
            exportData({
                titles: this.checkedCities,
                aaaIds: Updatelis
            }).then(res => {
                const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
                const fileName = 'Export.xlsx'; // 文件名
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = fileName; // 设置下载文件名
                link.click();
            })
       }
            this.UpdateEndDialog=false
            
        },
        UpdatecloseDialog() {
            this.UpdateEndDialog = false
        },
        //关闭弹窗
        closeDialog() {
            this.$emit('closeUpdateDialog', false);
        },
        onSubmitkoudian() {
            this.uPdateDialog = false;


            //  this.$emit('closeCloseDialog1', true);


        },
        // 全选
        handleCheckAllChange(val) {

            this.checkedCities = val ? this.cities : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;

            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },

    },
};
</script>
<style scoped>
.detail_cont {
    max-height: 86vh;
    overflow: auto;

}



.Exportoffline {
    background: #F5F5F5;
    border-radius: 6px;
    padding: 1.42857143rem;
    /* margin-bottom: 1.42857143; */
    margin-top: 10px;
}

.questionExportButton {
    background: #EEEEEE !important;
    /* border-radius: 10px; */
    font-size: 1.14285714rem;
    color: #999999;
    margin-left:2.14285714rem;
}

/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #8E86DD !important;
    border-color: #8E86DD!important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
    /* background-color: #ED8850 !important; */
    border-color: #8E86DD!important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
    border-color: #8E86DD!important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner::after {
    background-color: #8E86DD !important;
}

/deep/ .el-radio__inner:hover {
    
    border-color: #8E86DD !important;
    /* border-color: #409EFF; */
}

.Exportoffline {
    font-size: 1.42857143rem;
    color: #1A2332;
    line-height: 26px;
}

.ExportCheckbook {
    height: 24px;
    margin-top: 1.42857143rem;
}

.detail_cont_texttwo {
    font-size: 1.28571429rem;
    color: #666666;
    margin-top: 2.14285714rem;
    word-break: keep-all;
    text-align: justify;
}

/deep/ .el-checkbox-group {
    font-size: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

/deep/ .el-checkbox-group .el-checkbox {
    width: 45%;
    margin-right: 0px !important;
    margin-bottom: 1.42857143rem;
    align-items: center;
}

/deep/ .el-radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/deep/ .el-radio__input.is-checked+.el-radio__label,
/deep/ .el-radio__label {
    /* font-size: 18px; */
    /* font-size: 1.2857142857142858rem; */
    color: #1a2332;
}
/deep/ .el-radio__label{
    font-size: 1.4285714285714286rem;
}

</style>
